import React from 'react'
import { Builder } from '@builder.io/react'
import FaqSection from './faq/FaqSection'

type FaqProps = {
  sectionId: string;
  title: string;
  enableJumpLink: boolean;
  jumpLinkText?: string;
  jumpLinkTarget?: string;
  faqItems: {
    title: string;
    content: string;
  }[];
}

const Faq = ( props: FaqProps ) => {
  const {
    sectionId,
    title,
    enableJumpLink,
    jumpLinkText,
    jumpLinkTarget,
    faqItems
  } = props

  return (
    <section className="mx-auto px-4 w-full">
      <FaqSection
        sectionId={sectionId}
        title={title}
        jumpLinkText={enableJumpLink ? jumpLinkText : undefined}
        jumpLinkTarget={enableJumpLink ? jumpLinkTarget : undefined}
        faqItems={faqItems}
      />
    </section>
  )
}

Builder.registerComponent( Faq, {
  name: `Faq`,
  inputs: [
    {
      name: `sectionId`,
      type: `string`,
      required: true,
      helperText: `ID for this FAQ section (used for jump links)`,
      defaultValue: `faq-section-1`
    },
    {
      name: `title`,
      type: `string`,
      required: true,
      helperText: `Title of the FAQ section`,
      defaultValue: `Common Insurance Terms`
    },
    {
      name: `enableJumpLink`,
      type: `boolean`,
      defaultValue: false,
      helperText: `Enable a jump link to another FAQ section`
    },
    {
      name: `jumpLinkText`,
      type: `string`,
      helperText: `Text for the jump link`,
      defaultValue: `Jump to Section-1`,
      showIf: `options.get("enableJumpLink") === true`
    },
    {
      name: `jumpLinkTarget`,
      type: `string`,
      helperText: `ID of the target FAQ section to jump to`,
      defaultValue: `section-1`,
      showIf: `options.get("enableJumpLink") === true`
    },
    {
      name: `faqItems`,
      type: `list`,
      helperText: `FAQ items with title and content`,
      defaultValue: [
        {
          title: `Which CPAP supplies will insurance cover?`,
          content: `<p>In addition to your CPAP machine, most insurance providers will also cover regular replacement of your:</p>`
        }
      ],
      subFields: [
        {
          name: `title`,
          type: `string`,
          required: true,
          helperText: `Question title`
        },
        {
          name: `content`,
          type: `richText`,
          required: true,
          helperText: `Answer content`
        }
      ]
    }
  ]
})

export default Faq