import { GQLSnowdogResponse } from './types'

// static data to fall back on if graphql call fails
export const SnowdogMenusData: GQLSnowdogResponse = {
  "snowdogMenus": {
    "items": [
      {
        "identifier": `Main_sleep`,
        "menu_id": 4,
        "nodes": {
          "items": [
            {
              "additional_data": null,
              "creation_time": `2022-11-07 21:50:58`,
              "level": 0,
              "menu_id": 4,
              "node_id": 11604,
              "parent_id": 0,
              "position": 0,
              "title": `How It Works`,
              "type": `custom_url`,
              "update_time": `2022-11-07 21:50:59`,
              "url": `https://aeroflowsleep.com/how-aeroflow-sleep-works`
            },
            {
              "additional_data": null,
              "creation_time": `2022-11-07 21:50:58`,
              "level": 0,
              "menu_id": 4,
              "node_id": 11605,
              "parent_id": 0,
              "position": 1,
              "title": `Shop`,
              "type": `custom_url`,
              "update_time": `2022-11-07 21:50:59`,
              "url": undefined
            },
            {
              "additional_data": null,
              "creation_time": `2022-11-07 21:50:58`,
              "level": 0,
              "menu_id": 4,
              "node_id": 11610,
              "parent_id": 0,
              "position": 2,
              "title": `Sleep Resources`,
              "type": `custom_url`,
              "update_time": `2022-11-07 21:50:59`,
              "url": undefined
            },
            {
              "additional_data": null,
              "creation_time": `2022-11-07 21:50:58`,
              "level": 0,
              "menu_id": 4,
              "node_id": 11615,
              "parent_id": 0,
              "position": 3,
              "title": `About`,
              "type": `custom_url`,
              "update_time": `2022-11-07 21:51:00`,
              "url": undefined
            },
            {
              "additional_data": null,
              "creation_time": `2022-11-07 21:50:58`,
              "level": 1,
              "menu_id": 4,
              "node_id": 11606,
              "parent_id": 11605,
              "position": 0,
              "title": `CPAP Machines`,
              "type": `custom_url`,
              "update_time": `2022-11-07 21:50:59`,
              "url": `https://shop.aeroflowsleep.com/cpap-machines/`
            },
            {
              "additional_data": null,
              "creation_time": `2022-11-07 21:50:58`,
              "level": 1,
              "menu_id": 4,
              "node_id": 11607,
              "parent_id": 11605,
              "position": 1,
              "title": `CPAP Masks`,
              "type": `custom_url`,
              "update_time": `2022-11-07 21:50:59`,
              "url": `https://shop.aeroflowsleep.com/cpap-masks/`
            },
            {
              "additional_data": null,
              "creation_time": `2022-11-07 21:50:58`,
              "level": 1,
              "menu_id": 4,
              "node_id": 11608,
              "parent_id": 11605,
              "position": 2,
              "title": `CPAP Supplies`,
              "type": `custom_url`,
              "update_time": `2022-11-07 21:50:59`,
              "url": `https://shop.aeroflowsleep.com/cpap-supplies/`
            },
            {
              "additional_data": null,
              "creation_time": `2024-04-25 17:38:43`,
              "level": 1,
              "menu_id": 4,
              "node_id": 11818,
              "parent_id": 11605,
              "position": 3,
              "title": `CPAP Accessories`,
              "type": `custom_url`,
              "update_time": `2024-04-25 17:38:43`,
              "url": `https://shop.aeroflowsleep.com/cpap-accessories/`
            },
            {
              "additional_data": null,
              "creation_time": `2022-11-07 21:50:58`,
              "level": 1,
              "menu_id": 4,
              "node_id": 11609,
              "parent_id": 11605,
              "position": 4,
              "title": `Cleaning & Maintenance`,
              "type": `custom_url`,
              "update_time": `2022-11-07 21:50:59`,
              "url": `https://shop.aeroflowsleep.com/cpap-accessories/cpap-cleaning-products/`
            },
            {
              "additional_data": null,
              "creation_time": `2024-02-16 20:11:15`,
              "level": 1,
              "menu_id": 4,
              "node_id": 11810,
              "parent_id": 11610,
              "position": 0,
              "title": `Resource Center`,
              "type": `custom_url`,
              "update_time": `2024-02-16 20:11:15`,
              "url": `https://aeroflowsleep.com/resource-center`
            },
            {
              "additional_data": null,
              "creation_time": `2022-11-07 21:50:58`,
              "level": 1,
              "menu_id": 4,
              "node_id": 11613,
              "parent_id": 11610,
              "position": 1,
              "title": `How Insurance Works`,
              "type": `custom_url`,
              "update_time": `2022-11-07 21:50:59`,
              "url": `https://aeroflowsleep.com/insurance-rx-resources`
            },
            {
              "additional_data": null,
              "creation_time": `2022-11-07 21:50:58`,
              "level": 1,
              "menu_id": 4,
              "node_id": 11614,
              "parent_id": 11610,
              "position": 2,
              "title": `DreamStation Recall`,
              "type": `custom_url`,
              "update_time": `2022-11-07 21:50:59`,
              "url": `https://shop.aeroflowsleep.com/dreamstation-recall-who-is-affected-and-what-should-you-do`
            },
            {
              "additional_data": null,
              "creation_time": `2022-11-07 21:50:59`,
              "level": 1,
              "menu_id": 4,
              "node_id": 11616,
              "parent_id": 11615,
              "position": 0,
              "title": `About Us`,
              "type": `custom_url`,
              "update_time": `2022-11-07 21:51:00`,
              "url": `https://aeroflowsleep.com/about-aeroflow-sleep`
            },
            {
              "additional_data": null,
              "creation_time": `2022-11-07 21:50:59`,
              "level": 1,
              "menu_id": 4,
              "node_id": 11617,
              "parent_id": 11615,
              "position": 1,
              "title": `Editorial Policy`,
              "type": `custom_url`,
              "update_time": `2022-11-07 21:51:00`,
              "url": `https://shop.aeroflowsleep.com/aeroflow-sleep-editorial-policy`
            },
            {
              "additional_data": null,
              "creation_time": `2022-11-07 21:50:59`,
              "level": 1,
              "menu_id": 4,
              "node_id": 11619,
              "parent_id": 11615,
              "position": 2,
              "title": `Shipping Policy`,
              "type": `custom_url`,
              "update_time": `2022-11-07 21:51:00`,
              "url": `https://shop.aeroflowsleep.com/shipping`
            },
            {
              "additional_data": null,
              "creation_time": `2022-11-07 21:50:59`,
              "level": 1,
              "menu_id": 4,
              "node_id": 11620,
              "parent_id": 11615,
              "position": 3,
              "title": `Returns Policy`,
              "type": `custom_url`,
              "update_time": `2022-11-07 21:51:00`,
              "url": `https://shop.aeroflowsleep.com/returns-policy`
            },
            {
              "additional_data": null,
              "creation_time": `2023-11-08 14:16:08`,
              "level": 1,
              "menu_id": 4,
              "node_id": 11693,
              "parent_id": 11615,
              "position": 4,
              "title": `Our Sales Team`,
              "type": `custom_url`,
              "update_time": `2023-11-08 14:16:09`,
              "url": `https://aeroflowsleep.com/meet-our-sales-team`
            },
            {
              "additional_data": null,
              "creation_time": `2022-11-07 21:50:59`,
              "level": 1,
              "menu_id": 4,
              "node_id": 11621,
              "parent_id": 11615,
              "position": 5,
              "title": `Contact Us`,
              "type": `custom_url`,
              "update_time": `2022-11-07 21:51:00`,
              "url": `https://shop.aeroflowsleep.com/contact`
            }
          ]
        }
      }
    ]
  }
}