import React from 'react'
import { Builder } from '@builder.io/react'
import TextImageColumnItem from './components/TextImageColumnItem'
import { TextImageColumnItemTypes } from './types'

type ThreeColumnBlockItemProps = {
  items: TextImageColumnItemTypes[];
  title: string;
}

export const ThreeColumnBlock = ({ items, title }: ThreeColumnBlockItemProps ): JSX.Element => {
  return (
    <>
      {items && (
        <div className="text-center md:px-[45px] mx-5 md:pb-[60px]">
          <h3 className="font-inter mb-[45px] text-graphite text-2xl md:text-[36px]">{title}</h3>
          <div className="grid justify-center grid-cols-1 lg:grid-cols-3 md:gap-[45px]">
            {items.map( ( item ) => (
              <TextImageColumnItem key={item.content} {...item} />
            )
            )}
          </div>
        </div>
      )}
    </>
  )
}

Builder.registerComponent( ThreeColumnBlock, {
  name: `ThreeColumnBlock`,
  inputs: [
    {
      name: `title`,
      type: `text`,
      defaultValue: `Once you've completed our form, we will then...`
    },
    {
      name: `items`,
      type: `list`,
      subFields: [
        {
          name: `imgSrc`,
          type: `file`,
          allowedFileTypes: [ `jpeg`, `jpg`, `png`, `svg`, `webp` ]
        },
        {
          name: `altText`,
          type: `text`
        },
        {
          name: `title`,
          type: `text`
        },
        {
          name: `content`,
          type: `text`
        }
      ]
    }
  ]
})

export default ThreeColumnBlock