import React, { useState } from 'react'
import { CPAPJourneyTrackerStepItem } from '../types'
import { SlideToggleContent } from 'components/SlideToggle'
import { icoCaretUpThick, icoCaretDownThick, ellipsis } from 'resources/images'

function CPAPJourneyStepItem({ step } : { step: CPAPJourneyTrackerStepItem }) {
  const [ isOpen, setIsOpen ] = useState<boolean>( false )
  return (
    <div key={step.stepTitle} className="flex gap-4 w-full">
      <div className="flex flex-col justify-center items-center h-full">
        <div className="w-[1px] h-full min-h-[40px] bg-[#D5D8DC]" />
      </div>
      <div className="py-6 relative cursor-pointer w-full" onClick={() => setIsOpen( !isOpen )}>
        <div className="relative flex justify-between gap-2 w-full">
          <p>{ step.stepTitle }</p>
          <img
            src={isOpen ? icoCaretUpThick : icoCaretDownThick}
            alt={isOpen ? `caret up` : `caret down`}
          />
          <div className="absolute -left-6 top-1 transform">
            <img
              src={ellipsis}
              alt="tracker ellipsis"
              height={15}
              width={15}
            />
          </div>
        </div>
        <SlideToggleContent isVisible={isOpen} >
          <div
            className="text-graphite [&>p]:font-light py-4 [&>p>a]:text-deepSleepBlue [&>p>a]:underline [&>p>a]:decoration-deepSleepBlue"
            dangerouslySetInnerHTML={{
              __html: step.stepBody
            }}
          >
          </div>
        </SlideToggleContent>
      </div>
    </div>
  )
}

export default CPAPJourneyStepItem