import React from 'react'
import { Builder } from '@builder.io/react'

type ContactBannerProps = {
  header: string;
  phoneNumber: string;
  email: string;
  backgroundColor: string;
  textColor: string;
  phoneIcon: string;
  emailIcon: string;
}

export const ContactBanner = ({ header, phoneNumber, email, backgroundColor, textColor, phoneIcon, emailIcon }: ContactBannerProps ): JSX.Element => {

  return (
    <div
      className="w-full flex flex-col md:flex-row items-center justify-center py-16 gap-6 md:gap-3 px-3" style={{
        backgroundColor
      }}
    >
      <p
        className="text-2xl max-w-xs font-semibold text-center md:text-left" style={{
          color: textColor
        }}
      >{header}</p>
      <div
        className="flex flex-col font-light gap-5" style={{
          color: textColor
        }}
      >
        <div className="flex items-center justify-center md:justify-start gap-2">
          <img
            src={phoneIcon}
            alt="phone icon"
            height={24}
            width={24}
          />
          <a
            href={`tel:${phoneNumber.replace( /\D/g, `` )}`} className="underline" style={{
              textDecorationColor: textColor
            }}
          >
            <span>{phoneNumber}</span>
          </a>
        </div>
        <div className="flex items-center justify-center gap-2">
          <img
            src={emailIcon}
            alt="email icon"
            height={24}
            width={24}
          />
          <a
            href={`mailto:${email}`} className="underline" style={{
              textDecorationColor: textColor
            }}
          >
            <span>{email}</span>
          </a>
        </div>
      </div>
    </div>
  )
}

Builder.registerComponent( ContactBanner, {
  name: `ContactBanner`,
  inputs: [
    {
      name: `header`,
      type: `text`,
      defaultValue: `Didn't find your answer?\nContact us anytime.`
    },
    {
      name: `phoneNumber`,
      type: `text`,
      defaultValue: `1(866)560-6674`
    },
    {
      name: `phoneIcon`,
      type: `file`,
      allowedFileTypes: [ `png`, `svg` ],
      defaultValue: `https://cdn.builder.io/api/v1/image/assets%2F25b1a215ae9f430989a220ee58457f96%2Fe267b8820b694dfabf6e392682127f80`
    },
    {
      name: `email`,
      type: `text`,
      defaultValue: `info@aeroflowsleep.com`
    },
    {
      name: `emailIcon`,
      type: `file`,
      allowedFileTypes: [ `png`, `svg` ],
      defaultValue: `https://cdn.builder.io/api/v1/image/assets%2F25b1a215ae9f430989a220ee58457f96%2F5fd50a984c1747cb806630005c011382`
    },
    {
      name: `backgroundColor`,
      type: `color`,
      defaultValue: `#BBEBFC4D`
    },
    {
      name: `textColor`,
      type: `color`,
      defaultValue: `#003764`
    }
  ]
})

export default ContactBanner