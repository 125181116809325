import { Builder } from '@builder.io/react'
import { CPAPJourneyTrackerSegmentItem, CPAPJourneyTrackerStepItem, CPAPJourneyTrackerTypes } from './types'
import StepItem from './components/CPAPJourneyStepItem'

export const CPAPJourneyTracker = ({ segmentList }: CPAPJourneyTrackerTypes ): JSX.Element => {

  return (
    <div className="flex flex-col gap-16">
      {
        segmentList?.map( ( segment: CPAPJourneyTrackerSegmentItem ) => {
          return <div className="flex flex-col px-3" key={segment.header}>
            <div className="flex gap-4 items-center mb-3">
              <div className="h-[30px]">
                <img
                  src={segment.icon}
                  alt="step icon"
                  className="object-cover"
                />
              </div>

              <p className="text-lg font-semibold">{segment.header}</p>
            </div>
            {
              segment.stepsList?.map( ( step: CPAPJourneyTrackerStepItem ) => {
                return (
                  <StepItem key={step.stepTitle} step={step} />
                )
              })
            }
          </div>
        })
      }

    </div>

  )
}

Builder.registerComponent( CPAPJourneyTracker, {
  name: `CPAP Journey Tracker`,
  inputs: [
    {
      name: `segmentList`,
      type: `list`,
      subFields: [
        {
          name: `header`,
          type: `text`
        },
        {
          name: `icon`,
          type: `file`,
          allowedFileTypes: [ `jpeg`, `jpg`, `png`, `svg`, `webp` ]
        },
        {
          name: `stepsList`,
          type: `list`,
          subFields: [
            {
              name: `stepTitle`,
              type: `text`
            },
            {
              name: `stepBody`,
              type: `richText`
            }
          ]
        }
      ]
    }

  ]
})

export default CPAPJourneyTracker