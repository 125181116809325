import React from "react"
import { aeroflowSwirlLightBlue } from 'resources/images'
import { Builder } from '@builder.io/react'

type HeroBannerWithLogoProps = {
  subHeader?: string
  header: string
  bodyCopy?: string
  showLogo: boolean
  backgroundColor: string
}

const HeroBannerWithLogo = ( props: HeroBannerWithLogoProps ) => {
  const {
    subHeader,
    header,
    bodyCopy,
    showLogo,
    backgroundColor
  } = props

  return (
    <div
      className="
        sm:px-4 sm:py-[60px] md:px-[90px] md:py-[120px] relative
        sm:bg-center md:bg-[center_right_1rem] bg-no-repeat sm:bg-cover lg:bg-contain"
      style={{
        backgroundColor: backgroundColor || ``,
        backgroundImage: showLogo ? `url(${aeroflowSwirlLightBlue})` : ``
      }}
    >
      <div className="relative max-w-custom-inner z-10 mx-auto sm:text-center md:text-left flex flex-col gap-4">
        <div className=" w-full max-w-[644px]">
          <p className="text-white sm:text-xl lg:text-xl">{subHeader}</p>
          <h1 className="text-ctaYellow">{header}</h1>
          {
            bodyCopy && (
              <div
                className="text-white [&>p]:font-light py-4 [&>p>a]:text-deepSleepBlue [&>p>a]:underline [&>p>a]:decoration-deepSleepBlue [&>p]:text-sm py-4 block max-w-[516px]"
                style={{
                  fontWeight: 200
                }}
                dangerouslySetInnerHTML={{
                  __html: bodyCopy
                }}
              />
            )
          }
        </div>
      </div>
    </div>
  )
}

Builder.registerComponent( HeroBannerWithLogo, {
  name: `HeroBannerWithLogo`,
  inputs: [
    {
      name: `subHeader`,
      type: `text`,
      required: false,
      helperText: `Optional subheading text above the main header`,
      defaultValue: `We're here to help!`
    },
    {
      name: `header`,
      type: `text`,
      required: true,
      helperText: `First line of the main header text`,
      defaultValue: `Your Health Insurance and Prescription Questions Answered`
    },
    {
      name: `bodyCopy`,
      type: `richText`,
      required: false,
      helperText: `Optional body text below the header`,
      defaultValue: `<p>Navigating your health insurance requirements to obtain durable medical equipment needed for CPAP therapy can be confusing, but Aeroflow Sleep makes it easy!</p>`
    },
    {
      name: `showLogo`,
      type: `boolean`,
      defaultValue: true,
      helperText: `Show the Aeroflow swirl logo in the background`
    },
    {
      name: `backgroundColor`,
      type: `color`,
      defaultValue: `#003764`,
      helperText: `Choose a background color`
    }
  ]
})

export default HeroBannerWithLogo