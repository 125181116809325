import React from 'react'
import { CtaBannerTypes } from './types'
import { Builder } from '@builder.io/react'
import { Button } from '../../Button'

export const CtaBanner = ({ backgroundImgSrc, backgroundTitle, header, textColor, bodyText, callToActionLink, callToActionValue, isBtnPrimary }: CtaBannerTypes ): JSX.Element => {
  const BtnClasses = `h-11 rounded-[30px] !w-auto !my-0 ${isBtnPrimary ? `btn-primary` : `btn-secondary`}`
  return (
    <div
      className="w-full max-w-full bg-center bg-cover px-5 py-[60px]"
      style={{
        backgroundPosition: `center`,
        backgroundImage: `url(${backgroundImgSrc})`,
        backgroundSize: `cover`
      }}
      title={backgroundTitle}
    >
      <p
        className="text-xl mx-auto text-center mb-3 font-semibold" style={{
          color: textColor
        }}
      >{header}</p>
      {bodyText && (
        <div
          className="font-inter lg:text-lg text-center max-w-[400px] mx-auto"
          style={{
            color: textColor
          }}
          dangerouslySetInnerHTML={{
            __html: bodyText
          }}
        >
        </div>
      )}
      {callToActionLink && callToActionValue && (
        <div className="mx-auto mt-[30px] text-center">
          <Button
            className={BtnClasses}
            onClick={() => {
              window.location.href = callToActionLink
            }}
          >
            {callToActionValue}
          </Button>
        </div>
      )}
    </div>
  )
}

Builder.registerComponent( CtaBanner, {
  name: `CTA Banner with Image Background`,
  inputs: [
    {
      name: `backgroundImgSrc`,
      type: `file`,
      allowedFileTypes: [ `jpeg`, `jpg`, `png`, `svg`, `webp` ]
    },
    {
      name: `backgroundTitle`,
      type: `text`
    },
    {
      name: `header`,
      type: `text`
    },
    {
      name: `textColor`,
      type: `color`,
      defaultValue: `#222222`
    },
    {
      name: `bodyText`,
      type: `richText`
    },
    {
      name: `callToActionLink`,
      type: `url`
    },
    {
      name: `callToActionValue`,
      type: `text`
    },
    {
      name: `isBtnPrimary`,
      type: `boolean`,
      defaultValue: false
    }
  ]
})

export default CtaBanner