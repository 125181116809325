import React from 'react'
import { Builder } from '@builder.io/react'

type ContentBoxType = {
  position: `topLeft` | `topRight` | `bottomLeft` | `bottomRight`;
  title?: string;
  content?: string;
  content2?: string;
  image?: string;
  imageAlt?: string;
  backgroundColor?: string;
}

type ContentBoxesProps = {
  boxes?: ContentBoxType[];
}

const ContentBoxes = ({ boxes = [] }: ContentBoxesProps ) => {
  const renderTextBox = ( position: `topLeft` | `topRight` | `bottomLeft` | `bottomRight` ) => {
    const box = boxes.find( b => b.position === position ) || {
      position,
      title: ``,
      content: ``,
      content2: ``,
      image: ``,
      imageAlt: ``
    }

    return (
      <div className="flex items-center justify-center h-full w-full sm:px-6 md:px-10 lg:px-16 py-12 bg-white">
        <div className="text-center max-w-md mx-auto">
          {box.title && (
            <div
              className="mb-6 text-graphite h3"
              role="heading"
              aria-level={3}
            >
              {box.title}
            </div>
          )}
          <div className="[&>p]:font-light py-4 [&>p>a]:text-deepSleepBlue [&>p>a]:underline [&>p>a]:decoration-deepSleepBlue">
            {box.content && (
              <p className="mb-4">
                {box.content}
              </p>
            )}
            {box.content2 && (
              <p>
                {box.content2}
              </p>
            )}
          </div>
        </div>
      </div>
    )
  }

  const renderImageBox = ( position: `topLeft` | `topRight` | `bottomLeft` | `bottomRight` ) => {
    const box = boxes.find( b => b.position === position ) || {
      position,
      title: ``,
      content: ``,
      content2: ``,
      image: ``,
      imageAlt: ``
    }

    return (
      <div className="w-full h-full relative overflow-hidden">
        <img
          src={box.image}
          alt={box.imageAlt || ``}
          className="absolute inset-0 w-full h-full object-cover object-center"
          loading="lazy"
        />
      </div>
    )
  }

  return (
    <div className="w-full py-0 px-0 bg-white">
      <div className="w-full max-w-custom-inner mx-auto">
        <div className="flex flex-col md:flex-row">
          <div className="w-full md:w-1/2 aspect-square">
            {renderTextBox( `topLeft` )}
          </div>
          <div className="w-full md:w-1/2 aspect-square">
            {renderImageBox( `topRight` )}
          </div>
        </div>

        <div className="flex flex-col md:flex-row md:flex-row-reverse">
          <div className="w-full md:w-1/2 aspect-square">
            {renderTextBox( `bottomRight` )}
          </div>
          <div className="w-full md:w-1/2 aspect-square">
            {renderImageBox( `bottomLeft` )}
          </div>
        </div>
      </div>
    </div>
  )
}

Builder.registerComponent( ContentBoxes, {
  name: `ContentBoxes`,
  inputs: [
    {
      name: `boxes`,
      type: `list`,
      defaultValue: [
        {
          position: `topLeft`,
          title: `Our Mission`,
          content: `Aeroflow Sleep's mission is to help patients sleep well and live better. We live out our mission by providing resources, services, and the PAP equipment patients need to easily navigate their sleep apnea journey.`
        },
        {
          position: `topRight`,
          image: `/images/doctor-handshake.jpg`,
          imageAlt: `Doctor offering handshake`
        },
        {
          position: `bottomLeft`,
          image: `/images/couple-sleeping.jpg`,
          imageAlt: `Couple sleeping comfortably`
        },
        {
          position: `bottomRight`,
          title: `Our Story`,
          content: `Aeroflow Sleep provides the highest quality CPAP machines, masks, and supplies at the lowest allowable prices through insurance. Ever since, patients and physicians have trusted Aeroflow Sleep to provide CPAP equipment and supplies through insurance.`,
          content2: `Today, we have the privilege of serving thousands of patients with sleep apnea by providing CPAP supplies covered up to 100% through insurance.`
        }
      ],
      subFields: [
        {
          name: `position`,
          type: `string`,
          enum: [
            {
              label: `Top Left`,
              value: `topLeft`
            },
            {
              label: `Top Right`,
              value: `topRight`
            },
            {
              label: `Bottom Left`,
              value: `bottomLeft`
            },
            {
              label: `Bottom Right`,
              value: `bottomRight`
            }
          ],
          required: true
        },
        {
          name: `title`,
          type: `string`
        },
        {
          name: `content`,
          type: `text`
        },
        {
          name: `content2`,
          type: `text`,
          required: false
        },
        {
          name: `image`,
          type: `file`,
          allowedFileTypes: [ `jpeg`, `jpg`, `png`, `webp` ]
        },
        {
          name: `imageAlt`,
          type: `string`
        },
        {
          name: `backgroundColor`,
          type: `color`,
          defaultValue: `#ffffff`
        }
      ]
    }
  ]
})

export default ContentBoxes