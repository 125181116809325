import React from "react"
import { Builder } from '@builder.io/react'

type SectionOverviewProps = {
  sectionOverViewText: string
}

const SectionOverview = ( props: SectionOverviewProps ) => {
  const { sectionOverViewText } = props
  return (
    <div className="border-b sm:py-[30px] lg:py-[60px] sm:mx-4 lg:mx-[60px]">
      <p className="text-center max-w-2xl mx-auto sm:text-lg lg:text-xl text-graphite">
        {sectionOverViewText}
      </p>
    </div>
  )
}

Builder.registerComponent( SectionOverview, {
  name: `SectionOverview`,
  inputs: [
    {
      name: `sectionOverViewText`,
      type: `longText`,
      required: true,
      defaultValue: `Below you'll find answers to some of the most common questions about CPAP through insurance and CPAP prescriptions.`
    }
  ]
})

export default SectionOverview