import React, { useState } from 'react'
import { Builder } from '@builder.io/react'

type StaffMemberType = {
  name: string;
  title: string;
  credentials?: string;
  specialty?: string;
  bio: string;
  image?: string;
}

type MedicalStaffProps = {
  heading?: string;
  staffMembers?: StaffMemberType[];
}

const MedicalStaff = ( props: MedicalStaffProps ) => {
  const { heading = `Our Medical Staff`, staffMembers = [] } = props

  return (
    <div className="w-full pt-16 pb-12 px-4 text-white bg-deepSleepBlue">
      <div className="w-full max-w-custom-inner mx-auto">
        <div
          className="text-left mb-12 text-white text-4xl pl-4 font-semibold leading-none"
          role="heading"
          aria-level={2}
        >
          {heading}
        </div>

        <div className="grid sm:grid-cols-1 md:grid-cols-3 gap-x-8 gap-y-8 px-4">
          {staffMembers && staffMembers.map( ( member, index ) => (
            <StaffMemberCard key={index} member={member} />
          ) )}
        </div>
      </div>
    </div>
  )
}

const StaffMemberCard = ({ member }: { member: StaffMemberType }) => {
  const [ expanded, setExpanded ] = useState( false )

  // Truncate bio for initial display
  const truncatedBio = member.bio.length > 260 ? `${member.bio.substring( 0, 260 )}...` : member.bio

  return (
    <div className="text-white h-full flex flex-col mb-8 px-2">
      <div>
        {member.image && (
          <img className="w-[200px] h-[200px] object-cover rounded-full mb-5" src={member.image} alt={member.name} />
        )}
        <div
          className="text-white mb-1 text-xl leading-tight font-semibold"
          role="heading"
          aria-level={3}
        >
          {member.name}
          {member.credentials && ` ${member.credentials}`}
        </div>

        <p className="mb-3 text-base leading-tight font-normal text-[#BBEBFC]">
          {member.title}
        </p>

        {member.specialty && (
          <p className="italic mb-3 text-white">
            {member.specialty}
          </p>
        )}

        <div className="mt-1 [&>p]:font-light py-4 [&>p>a]:text-deepSleepBlue [&>p>a]:underline [&>p>a]:decoration-deepSleepBlue [&>p]:text-sm">
          <p className="mb-2 text-white max-w-xs">
            {expanded ? member.bio : truncatedBio}
          </p>
        </div>
      </div>

      {member.bio.length > 260 && (
        <button
          className="mt-1 flex items-center text-left cursor-pointer focus:outline-none bg-transparent border-none p-0 text-sm font-normal text-[#BBEBFC]"
          onClick={() => setExpanded( !expanded )}
          type="button"
        >
          <span className="underline">{`${expanded ? `Collapse` : `Read More`}`}</span>
          <span className="ml-1">{expanded ? `-` : `+`}</span>
        </button>
      )}
    </div>
  )
}

Builder.registerComponent( MedicalStaff, {
  name: `MedicalStaff`,
  inputs: [
    {
      name: `heading`,
      type: `string`,
      defaultValue: `Our Medical Staff`,
      helperText: `Main heading for the medical staff section`
    },
    {
      name: `staffMembers`,
      type: `list`,
      defaultValue: [
        {
          name: `Michelle Worley`,
          title: `Director of Clinical Operations`,
          bio: `Michelle has been involved in the development of the Aeroflow Sleep program from the beginning. She helps ensure all of our clinical processes are patient-focused and that our patient care is top-notch. She offers her expertise on various sleep topics by reviewing our medical content, and she is also featured in outlets like Sleep Review Magazine and Mental Floss.`
        },
        {
          name: `Robert Yapundich`,
          title: `Medical Director of Sleep Medicine & Neurology`,
          credentials: `MD`,
          bio: `Dr. Yapundich is a board certified neurologist and medical director for the Aeroflow Health Sleep division. He has practiced within his community for 24 years and also served patients throughout the country via telemedicine.`
        },
        {
          name: `Mitchell Stotland`,
          title: `Medical Director`,
          credentials: `MD, MPH`,
          bio: `Dr. Stotland is a Board Certified allopathic trained physician with a Masters Degree in Public Health. He focuses on evidence based treatments that are holistic, multidimensional and patient-centered. Dr. Stotland believes in empowering patients to be healthy and uses a multitude of methods to prevent and treat disease. He is able to see patients in any region of the United States and has extensive leadership experience clinically as a Medical Director and Chief Medical Officer of several companies.`
        },
        {
          name: `Carleara Weiss`,
          title: `Sleep Science Advisor`,
          credentials: `PhD, MSH, RN`,
          bio: `Dr. Carleara Weiss, Ph.D., MSH, RN is a Research Assistant Professor at the State University of New York in Buffalo, NY. Originally from Brazil, she has over 15 years of experience as an Adult-Geriatric Nurse. She was recently featured in Buzzfeed, EatingWell, Women's Health, and on the Well+Good podcast. She provides expert advice on what impacts patients' sleep, advocates for better health and wellness care, and promotes diversity, equity, and inclusion through many digital platforms. You can even find her on YouTube, as the namesake for our video segment: "Weiss Words."
`
        },
        {
          name: `Monique May`,
          title: `Medical Advisory Board Member`,
          credentials: `MD, MHA`,
          bio: `Known as the Physician in the Kitchen®, Dr. Monique May is a Board-Certified Family Physician who specializes in addressing comorbidities through a healthy lifestyle. She practiced clinically for 20 years, trained restaurant-chain Denny's staff internally by offering them curated nutrition guides and recipes in a series of videos, and recently released a book entitled, "Doc, Fix My Plate!" All of this to say, her expert advice on how to prevent and reduce sleep-disordered breathing (like sleep apnea) with plant-based food is invaluable to Aeroflow Sleep and its patients.`
        },
        {
          name: `Teresa Power DeNike`,
          title: `Sleep Science Advisor`,
          credentials: `BS, CCSH`,
          bio: `Teresa is a board-certified clinical sleep educator, national speaker, and the founder of Sleep Better NYC. After 14 years in healthcare sales and consulting, Teresa witnessed countless family, friends, and patients struggle through the typical pathways for sleep support. Sleep Better NYC was founded as a solution, to provide actionable guidance from a trusted source.`
        },
        {
          name: `Dr. Christopher J. Allen`,
          title: `Sleep Clinical Advisor`,
          bio: `Known as "Sleep Dr. Chris," Dr. Chris Allen is a Board-Certified Sleep Medicine Physician and Pediatric Neurologist. He has received certifications by The American Board of Psychiatry and Neurology with subspecialties in Sleep Medicine and Child Neurology. Furthermore, he has his own practice and is knowledgeable in heart health, too`
        }
      ],
      subFields: [
        {
          name: `name`,
          type: `string`
        },
        {
          name: `title`,
          type: `string`
        },
        {
          name: `credentials`,
          type: `string`,
          required: false
        },
        {
          name: `specialty`,
          type: `string`,
          required: false
        },
        {
          name: `bio`,
          type: `text`
        },
        {
          name: `image`,
          type: `file`,
          allowedFileTypes: [ `jpeg`, `jpg`, `png`, `webp` ],
          required: false
        }
      ]
    }
  ]
})

export default MedicalStaff