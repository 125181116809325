import React from 'react'
import { Builder } from '@builder.io/react'
import { ImageWithTextGridTypes } from './types'
import { Button } from '../../Button'

export const ImageWithTextBlock = ({ imgSrc, altText, title, secondaryTitle, bodyText, callToActionLink, callToActionValue, htmlId, isColumn }: ImageWithTextGridTypes ): JSX.Element => {
  return (
    <div id={htmlId} className={`flex ${isColumn ? `flex-col` : `flex-col md:flex-row`} justify-center lg:mx-auto mx-5 mb-[60px] gap-5 md:gap-[60px]`}>
      {imgSrc && (
        <div className={`${!isColumn && `w-full md:w-1/2`} lg:mb-0 mb-5 relative cursor-pointer`}>
          <img
            src={imgSrc}
            alt={altText}
            className="rounded-[5px] block w-full"
          />
        </div>
      )}
      <div className={`${!isColumn && `w-full md:w-1/2`} flex flex-col`}>
        {title && (
          <h2 className="text-left font-inter text-graphite font-semibold mb-[15px] md:text-[40px] md:leading-[40px] text-[28px] leading-[28px]">
            {title}
          </h2>
        )}
        {secondaryTitle && (
          <h3 className="text-left font-inter text-deepSleepBlue font-[500] mb-8 md:text-[36px] md:leading-[36px] text-[24px] leading-[24px]">
            {secondaryTitle}
          </h3>
        )}
        <div className="mx-auto text-left">
          {bodyText && (
            <div
              className="text-graphite font-inter lg:text-lg grid gap-[10px]"
              dangerouslySetInnerHTML={{
                __html: bodyText
              }}
            >
            </div>
          )}
          {callToActionLink && callToActionValue && (
            <div className="mx-auto md:mt-[52px] mt-[30px] lg:text-left text-center">
              <Button
                className="h-11 btn-secondary rounded-[30px] !w-auto"
                onClick={() => {
                  window.location.href = callToActionLink
                }}
              >
                {callToActionValue}
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

Builder.registerComponent( ImageWithTextBlock, {
  name: `Image With Text Block`,
  inputs: [
    {
      name: `imgSrc`,
      type: `file`,
      allowedFileTypes: [ `jpeg`, `jpg`, `png`, `svg`, `webp` ]
    },
    {
      name: `isColumn`,
      type: `boolean`,
      defaultValue: false
    },
    {
      name: `altText`,
      type: `text`
    },
    {
      name: `title`,
      type: `text`
    },
    {
      name: `secondaryTitle`,
      type: `text`
    },
    {
      name: `bodyText`,
      type: `richText`
    },
    {
      name: `callToActionLink`,
      type: `url`
    },
    {
      name: `callToActionValue`,
      type: `text`
    },
    {
      name: `htmlId`,
      type: `text`
    }
  ]
})

export default ImageWithTextBlock