import React from 'react'
import { Builder } from '@builder.io/react'
import { TextCtaCard } from './components/TextCtaCard'
import { TextCtaCardTypes } from './types'

type SleepJourneyItemProps = {
  items: TextCtaCardTypes[];
  title: string;
}

export const SleepJourney = ({ items, title }: SleepJourneyItemProps ): JSX.Element => {
  return (
    <>
      {items && (
        <div className="mx-auto">
          <h2 className="md:text-[40px] md:leading-[40px] text-[28px] leading-[28px] text-left font-inter font-semibold text-graphite mb-[30px] md:mb-12">{title}</h2>
          <div className="grid md:grid-cols-1 lg:grid-cols-4 gap-[30px] [&>p]:font-light py-4 [&>p>a]:text-deepSleepBlue [&>p>a]:underline [&>p>a]:decoration-deepSleepBlue">
            {items.map( ( item ) => (
              <TextCtaCard key={item.title} {...item} />
            )
            )}
          </div>
        </div>
      )}
    </>
  )
}

Builder.registerComponent( SleepJourney, {
  name: `SleepJourney`,
  inputs: [
    {
      name: `title`,
      type: `text`,
      defaultValue: `Where are you in your sleep journey?`
    },
    {
      name: `items`,
      type: `list`,
      subFields: [
        {
          name: `title`,
          type: `text`
        },
        {
          name: `bodyText`,
          type: `text`
        },
        {
          name: `jumpToTargetId`,
          type: `text`,
          helperText: `ID of the section to jump to when this item is clicked`
        },
        {
          name: `callToActionLink`,
          type: `url`
        },
        {
          name: `callToActionValue`,
          type: `text`
        },
        {
          name: `backgroundColor`,
          type: `text`
        }
      ]
    }
  ]
})

export default SleepJourney