import React from 'react'
import { TextImageColumnItemTypes } from '../types'

export const TextImageColumnItem = ({ imgSrc, altText, title, content }: TextImageColumnItemTypes ): JSX.Element => {
  return (
    <div className="text-center mb-[45px] md:mb-0">
      {imgSrc && (
        <img
          src={imgSrc}
          className="my-0 mx-auto sm:mb-1 md:mb-4"
          alt={altText}
          loading="lazy"
        />
      )}
      {title && (
        <h5 className="normal-case tracking-normal font-semibold text-lg md:text-2xl font-inter text-graphite mb-[15px]">{title}</h5>
      )}
      {content && (
        <p className="font-inter text-graphite lg:text-lg">{content}</p>
      )}
    </div>
  )
}

export default TextImageColumnItem