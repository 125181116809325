import React, { useState } from 'react'
import { ResupplyScheduleProductType } from '../types'
import { SlideToggleContent } from 'components/SlideToggle'
import { icoCaretUpThick, icoCaretDownThick } from 'resources/images'

function ResupplyScheduleProduct({ product } : { product: ResupplyScheduleProductType }) {
  const [ isOpen, setIsOpen ] = useState<boolean>( false )
  return (
    <div className="cursor-pointer" onClick={() => setIsOpen( !isOpen )}>
      <div className="w-full flex justify-between">
        <p className="font-light text-lg">{product.product}</p>

        <img
          src={isOpen ? icoCaretUpThick : icoCaretDownThick}
          alt={isOpen ? `caret up` : `caret down`}
        />
      </div>
      <SlideToggleContent isVisible={isOpen} >
        <div
          className="text-graphite [&>p]:font-light py-4 [&>p>a]:text-deepSleepBlue [&>p>a]:underline [&>p>a]:decoration-deepSleepBlue [&>p]:text-sm py-4 flex flex-col gap-2"
          dangerouslySetInnerHTML={{
            __html: product.productDescription
          }}
        >
        </div>
      </SlideToggleContent>
    </div>
  )
}

export default ResupplyScheduleProduct