import React from 'react'
import { Builder } from '@builder.io/react'

type ValueBoxType = {
  title: string;
  description: string;
}

type WhoWeAreProps = {
  heading?: string;
  valueBoxes?: ValueBoxType[];
  backgroundColor?: string;
}

const WhoWeAre = ( props: WhoWeAreProps ) => {
  const {
    heading = `Who We Are`,
    valueBoxes = [],
    backgroundColor = `rgba(187, 235, 252, 0.5)`
  } = props

  return (
    <div
      className="w-full sm:py-8 md:py-12 px-4 md:pb-16"
      style={{
        backgroundColor
      }}
    >
      <div className="w-full max-w-custom-inner mx-auto">
        <div className="text-left mt-16 mb-16 px-4">
          <h2 className="text-graphite">{heading}</h2>
        </div>

        <div className="grid sm:grid-cols-1 md:grid-cols-3 gap-8">
          {valueBoxes && valueBoxes.map( ( box, index ) => (
            // eslint-disable-next-line react/no-array-index-key
            <div key={index} className="flex flex-col px-4">
              <div className="mb-9">
                <h3 className="text-graphite">{box.title}</h3>
              </div>

              <div
                className="[&>p]:font-light py-4 [&>p>a]:text-deepSleepBlue [&>p>a]:underline [&>p>a]:decoration-deepSleepBlue mb-16 text-graphite"
                dangerouslySetInnerHTML={{
                  __html: box.description
                }}
              />

              <div className="mt-auto">
                <hr className="border-t-[5px] border-sleepBlue w-full mb-16" />
              </div>
            </div>
          ) )}
        </div>
      </div>
    </div>
  )
}

Builder.registerComponent( WhoWeAre, {
  name: `WhoWeAre`,
  inputs: [
    {
      name: `heading`,
      type: `string`,
      defaultValue: `Who We Are`,
      helperText: `Main heading for the section`
    },
    {
      name: `backgroundColor`,
      type: `color`,
      defaultValue: `rgba(187, 235, 252, 0.5)`,
      helperText: `Background color for the section`
    },
    {
      name: `valueBoxes`,
      type: `list`,
      defaultValue: [
        {
          title: `We Value the Highest Quality Equipment`,
          description: `<p>We believe quality CPAP equipment is critical for a successful sleep apnea treatment. That's why we offer equipment from the top brand names like ResMed, Fisher & Paykel, and Resvent.</p>`
        },
        {
          title: `We are Committed to Patient Education`,
          description: `<p>In addition to providing CPAP supplies through insurance, we are also committed to providing our patients with the education and resources necessary to achieve excellent sleep, night after night. We also offer an Online Resource Center, featuring helpful materials and video tutorials, to ensure our patients have the tools needed for successful PAP therapy.</p>`
        },
        {
          title: `We Are Part of the Aeroflow Health Family`,
          description: `<p>Aeroflow Sleep is a subsidiary of Aeroflow Health that specializes in providing CPAP supplies through insurance. Put simply, we help patients navigate insurance guidelines to ensure they receive the durable medical equipment they need without any added stress, complexity, or costs.</p>`
        }
      ],
      subFields: [
        {
          name: `title`,
          type: `string`,
          helperText: `Title for this value box`
        },
        {
          name: `description`,
          type: `richText`,
          helperText: `Description text for this value box`
        }
      ]
    }
  ]
})

export default WhoWeAre