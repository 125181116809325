import React from 'react'
import { Builder } from '@builder.io/react'
import { ResupplyScheduleProductType, ResupplyScheduleTimeFrameItemType, ResupplyScheduleTimeFrameProps } from './types'
import { icoClockCMS } from 'resources/images'
import ResupplyScheduleProduct from './components/ResupplyScheduleProduct'

export const ResupplySchedule = ({ header, timeFrameItems }: ResupplyScheduleTimeFrameProps ): JSX.Element => {

  return (
    <div>
      <p className="text-left font-inter text-2xl text-graphite font-semibold md:leading-[40px] text-[28px] leading-[28px]">{header}</p>
      {timeFrameItems && (
        timeFrameItems?.map( ( item: ResupplyScheduleTimeFrameItemType ) => {
          return <div key={item.timeFrameValue} className="py-8 border-b border-b-lightgray">
            <div className="p-1 px-4 rounded-lg bg-[#BBEBFC4D] w-fit flex gap-2">
              <img
                src={icoClockCMS}
                alt="clock icon"
                width={15}
                height={15}
              />
              <p className="font-light">{item.timeFrameValue}</p>
            </div>
            <div className="py-6 flex flex-col gap-8">
              {
                item.timeFrameProducts && (
                  item.timeFrameProducts?.map( ( product: ResupplyScheduleProductType ) => {
                    return <ResupplyScheduleProduct key={product.product} product={product} />
                  })
                )
              }
            </div>

          </div>
        })
      )}
    </div>
  )
}

Builder.registerComponent( ResupplySchedule, {
  name: `ResupplySchedule`,
  inputs: [
    {
      name: `header`,
      type: `text`,
      defaultValue: `Equipment resupply schedule`
    },
    {
      name: `timeFrameItems`,
      type: `list`,
      subFields: [
        {
          name: `timeFrameValue`,
          type: `text`
        },
        {
          name: `timeFrameProducts`,
          type: `list`,
          subFields: [
            {
              name: `product`,
              type: `text`
            },
            {
              name: `productDescription`,
              type: `richText`
            }
          ]
        }
      ]
    }
  ]
})

export default ResupplySchedule