import { SlideToggleContent } from 'components/SlideToggle'
import React, { useState } from 'react'
import { icoMinusSleepBlue, icoPlusSleepBlue } from 'resources/images'

type FaqItemProps = {
  title: string;
  content: string;
}

const FaqItem = ({ title, content }: FaqItemProps ) => {
  const [ isOpen, setIsOpen ] = useState( false )

  const toggleAccordion = () => {
    setIsOpen( !isOpen )
  }

  if ( !title || !content ) return null

  return (
    <div className="bg-[#F3F5F7]">
      <div
        className="flex items-center py-4 cursor-pointer sm:p-4 lg:p-[30px]"
        onClick={toggleAccordion}
      >
        <span className="min-w-[34px]">
          <img
            src={isOpen ? icoMinusSleepBlue : icoPlusSleepBlue}
            alt={isOpen ? `Collapse` : `Expand`}
            className="block sm:pr-4 lg:pr-[30px]"
          />
        </span>
        <h3 className="text-graphite sm:text-lg lg:text-2xl font-inter leading-normal">{title}</h3>
      </div>
      <SlideToggleContent isVisible={isOpen}>
        <div
          className="sm:px-[50px] sm:pb-4 lg:px-[78px] lg:pb-[30px] [&>ul]:list-disc [&>ul]:p-[inherit] [&>ol]:p-[inherit] [&>ol]:list-decimal"
          dangerouslySetInnerHTML={{
            __html: content
          }}
        />
      </SlideToggleContent>
    </div>
  )
}

export default FaqItem