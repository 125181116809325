import React from 'react'
import { Button } from 'components/Button'
import { TextCtaCardTypes } from '../types'
import { Link } from 'react-scroll'

export const TextCtaCard = ({ title, bodyText, callToActionLink, callToActionValue, backgroundColor, jumpToTargetId }: TextCtaCardTypes ): JSX.Element => {
  return (
    <div
      className="flex flex-col py-[45px] xl:py-[45px] lg:py-[30px] px-[30px] xl:px-[30px] lg:px-[10px] rounded-[15px] justify-around"
      style={{
        background: `${backgroundColor}`
      }}
    >
      <div className="text-center flex flex-col lg:justify-center justify-around">
        {title && (
          <h3 className="md:text-[36px] text-[24px] text-graphite mb-3 font-semibold font-inter">
            {title}
          </h3>
        )}
        {bodyText && (
          <p className="pb-10 text-graphite font-inter md:text-lg">{bodyText}</p>
        )}
        {
          jumpToTargetId && (
            <div className="mx-auto">
              <Button
                className="h-11 btn-secondary rounded-[30px] lg:!text-sm xl:!text-lg"
              >
                <Link
                  to={jumpToTargetId}
                  smooth
                  offset={-80}
                  duration={500}
                >
                  {callToActionValue}
                </Link>
              </Button>
            </div>
          )
        }
        {callToActionLink && !jumpToTargetId && callToActionValue && (
          <div className="mx-auto">
            <Button
              className="h-11 btn-secondary rounded-[30px] lg:!text-sm xl:!text-lg"
              onClick={() => {
                window.location.href = callToActionLink
              }}
            >
              {callToActionValue}
            </Button>
          </div>
        )}
      </div>
    </div>
  )
}
