import React from "react"
import { Builder } from "@builder.io/react"
import { Button } from "../../Button"

type QuestionsSupportSectionProps = {
  heading: string
  body: string
  buttonText: string
  buttonLink: string
  disclaimerText: string
  isPrimaryButton?: boolean
}

const QuestionsSupportSection = ( props: QuestionsSupportSectionProps ) => {
  const {
    heading,
    body,
    buttonText,
    buttonLink,
    disclaimerText,
    isPrimaryButton
  } = props

  const buttonClasses = `h-11 rounded-[30px] !w-auto ${isPrimaryButton ? `btn-primary` : `btn-secondary`}`

  return (
    <div className="w-full">
      <div className="w-full h-[8px] sleep-gradient-darkToLight"></div>
      <div className="py-16 px-4 md:px-8 lg:px-16 flex flex-col items-center bg-[#BBEBFC80]">
        <div className="w-full mx-auto">
          <div className="max-w-custom-inner mx-auto">
            <div className="max-w-2xl mx-auto text-center">
              <h2 className="text-2xl md:text-3xl text-aeroflowNavy">{heading}</h2>

              <div
                className="py-8 flex flex-col gap-2 [&>p]:font-light py-4 [&>p>a]:text-deepSleepBlue [&>p>a]:underline [&>p>a]:decoration-deepSleepBlue md:[&>p]:font-light py-4 [&>p>a]:text-deepSleepBlue [&>p>a]:underline [&>p>a]:decoration-deepSleepBlue [&>p]:text-sm"
                dangerouslySetInnerHTML={{
                  __html: body
                }}
              >
              </div>

              <div className="text-center">
                <Button
                  className={buttonClasses}
                  onClick={() => {
                    window.location.href = buttonLink
                  }}
                >
                  {buttonText}
                </Button>
              </div>

              <p className="p-xs mt-8 italic text-center">
                {disclaimerText}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

Builder.registerComponent( QuestionsSupportSection, {
  name: `QuestionsSupportSection`,
  inputs: [
    {
      name: `heading`,
      type: `text`,
      required: true,
      defaultValue: `Questions? We're Here to Help!`
    },
    {
      name: `body`,
      type: `richText`,
      required: true
    },
    {
      name: `buttonText`,
      type: `text`,
      required: true,
      defaultValue: `Contact Us`
    },
    {
      name: `buttonLink`,
      type: `url`,
      required: true,
      defaultValue: `/contact`
    },
    {
      name: `disclaimerText`,
      type: `longText`,
      required: false,
      defaultValue: `*Important Note: CPAP supplies covered through insurance are paid for according to your plan's rules for cost-sharing. This would include whether or not you have a deductible to pay on your plan, the amount that you do owe out-of-pocket (called your coinsurance) with your insurance plan will go toward paying down your deductible and out-of-pocket maximum, and what supplies are covered under your specific benefits package.`
    },
    {
      name: `isPrimaryButton`,
      type: `boolean`,
      defaultValue: false,
      helperText: `Set to true for yellow primary button, false for blue secondary button`
    }
  ]
})

export default QuestionsSupportSection