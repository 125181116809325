import React from 'react'
import FaqHeader from './FaqHeader'
import FaqItem from './FaqItem'

type FaqItemType = {
  title: string;
  content: string;
};

type FaqSectionProps = {
  sectionId: string;
  title: string;
  jumpLinkText?: string;
  jumpLinkTarget?: string;
  faqItems: FaqItemType[];
};

const FaqSection = ({
  sectionId,
  title,
  jumpLinkText,
  jumpLinkTarget,
  faqItems
}: FaqSectionProps ) => {
  return (
    <div id={sectionId} className="sm:py-[30px] lg:py-[60px]">
      <FaqHeader
        title={title}
        jumpLinkText={jumpLinkText}
        jumpLinkTarget={jumpLinkTarget}
      />
      <div className="sm:space-y-4 lg:space-y-[30px]">
        {faqItems.map( ( item ) => (
          <FaqItem
            key={sectionId}
            title={item.title}
            content={item.content}
          />
        ) )}
      </div>
    </div>
  )
}

export default FaqSection