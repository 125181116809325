import React from "react"
import { Link } from 'react-scroll'

type FaqHeaderProps = {
  title: string;
  jumpLinkText?: string;
  jumpLinkTarget?: string;
}

const FaqHeader = ({ title, jumpLinkText, jumpLinkTarget }: FaqHeaderProps ) => {
  return (
    <div className="flex flex-col sm:pb-[30px] lg:pb-[60px]">
      <div className="flex-col justify-between items-center text-center">
        {title &&
          <h2 className="text-2xl font-inter text-aeroflowNavy font-semibold sm:text-[28px] lg:text-[40px] sm:pb-[10px] lg:pb-4 leading-normal">{title}</h2>
        }
        {jumpLinkText && jumpLinkTarget && (
          <Link
            to={jumpLinkTarget}
            smooth
            offset={-80}
            duration={500}
            className="text-aeroflowNavy underline sm:text-xs lg:text-sm cursor-pointer"
          >
            {jumpLinkText}
          </Link>
        )}
      </div>
    </div>
  )
}

export default FaqHeader