import React, { useEffect, useRef, useState } from 'react'

import MenuDropdownButton from './menuDropdownButton'
import SubmenuList from './submenuList'
import { SlideToggleContent } from 'components/SlideToggle'
import styled from 'styled-components'
import { useSnowdogMenusStore } from './state'

// there are some strange gotchas trying to set a dynamic property with style={{}} or tailwind css class so hopefully this will work
const StyledGridWrapperUl = styled.ul`
  grid-template-columns: ${props => { return `repeat(${props.colLen}, minmax(0, 1fr))` }};
`

const StyledGridWrapperDiv = styled.div`
  grid-template-columns: ${props => { return `repeat(${props.colLen}, minmax(0, 1fr))` }};
`

const MegaMenu = () => {
  const [ activeSubmenu, setActiveSubmenu ] = useState( null )
  const [ menu, setMenu ] = useState( [] )

  const menuRef = useRef( null )
  const { menus } = useSnowdogMenusStore()

  const activeMenuItem = menu
    .map( ( item, index ) => {
      item.number = index + 1

      return item
    }).find( item => { return item.title === activeSubmenu })

  useEffect( () => {
    if ( menus.data ) {
      setMenu( menus.data )
    }
  }, [ menus ] )

  useEffect( () => {
    const handleClickAway = event => {
      if ( !menuRef?.current?.contains( event.target ) ) {
        setActiveSubmenu( null )
      }
    }
    const cleanupListener = () => {
      window.removeEventListener( `click`, handleClickAway )
    }
    window.addEventListener( `click`, handleClickAway )

    return cleanupListener
  }, [ menuRef ] )

  const hasSubmenus = activeMenuItem?.submenus?.length > 1

  if ( !menu ) return null

  return (
    <nav ref={menuRef} className="relative w-full md:pt-6 md:pb-4 lg:pt-8 lg:pb-6 z-20 border-b border-[#B7B7B7]">
      {
        !menus.loading &&
        <>
          <StyledGridWrapperUl
            colLen={menu.length}
            className="sm:hidden md:grid md:pl-4 lg:pl-0 md:w-full lg:w-full mx-auto list-none"
          >
            {menu.map( menuItem => {
              const hasSubmenu = menuItem.submenus?.length > 0

              return (
                <MenuDropdownButton
                  hasSubmenu={hasSubmenu}
                  key={`${menuItem.title}`}
                  title={menuItem.title}
                  url={hasSubmenu ? null : menuItem.url}
                  activeSubmenu={activeSubmenu}
                  setActiveSubmenu={setActiveSubmenu}
                />
              )
            })}
          </StyledGridWrapperUl>
          <div className="absolute w-full bg-lightGray box-shadow-light md:mt-[17px] lg:mt-[25px]">
            <StyledGridWrapperDiv
              colLen={menu.length}
              className={`sm:hidden md:grid md:w-full lg:w-10/12 md:pl-4 lg:pl-0 mx-auto`}
            >
              <div
                style={{
                  gridColumnStart: ( () => {

                    // submenus ? let them expand to full width of grid
                    if ( activeMenuItem?.submenus?.length > 1 ){
                      return `span ${menu.length}`
                    }

                    return activeMenuItem?.number || 1

                  })(),
                  gridColumnEnd: `span ${menu.length}`
                }}
              >
                <SlideToggleContent hasSubmenus={hasSubmenus} isVisible={Boolean ( activeSubmenu && activeMenuItem )}>
                  <SubmenuList submenus={activeMenuItem?.submenus} />
                </SlideToggleContent>
              </div>
            </StyledGridWrapperDiv>
          </div>
        </>
      }
    </nav>
  )
}

export default MegaMenu
